import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["src", "dst"];

  updateSrc() {
    this.dstTarget.value = this.srcTarget.value.split("T")[0] + "T" + this.dstTarget.value.split("T")[1];
  }

  updateDst() {
    this.srcTarget.value = this.dstTarget.value.split("T")[0] + "T" + this.srcTarget.value.split("T")[1];
  }
}
