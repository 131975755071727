import { Controller } from "stimulus";

// click_controller clicks things
export default class extends Controller {
  static targets = ["clickable"];
  static values = {
    key: String,
  };

  connect() {
    if (this.hasKeyValue) {
      this.boundKeyDown = this.keyDown.bind(this);
      document.body.addEventListener("keydown", this.boundKeyDown);
    }
  }

  keyDown({ key, target }) {
    if (target.tagName === "INPUT") return;
    if (target.tagName === "TEXTAREA") return;

    if (key === this.keyValue) {
      this.click();
    }
  }

  disconnect() {
    if (this.hasKeyValue) {
      document.body.removeEventListener("keydown", this.boundKeyDown);
    }
  }

  click() {
    this.clickableTargets.forEach((el) => el.click());
  }
}
