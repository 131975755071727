import * as Turbo from "@hotwired/turbo";
import "form-request-submit-polyfill"; // come on Safari get it together

// set up Stimulus
import { Application as Stimulus } from "stimulus";
const application = Stimulus.start();

application.register("append", require("../controllers/append_controller").default);
application.register("capacitor", require("../controllers/capacitor_controller").default);
application.register("capacitor-open-app", require("../controllers/capacitor_open_app_controller").default);
application.register("click", require("../controllers/click_controller").default);
application.register("codemirror", require("../controllers/codemirror_controller").default);
application.register("context-menu", require("../controllers/context_menu_controller").default);
application.register("flash", require("../controllers/flash_controller").default);
application.register("form", require("../controllers/form_controller").default);
application.register("form-reset", require("../controllers/form_reset_controller").default);
application.register("numpad", require("../controllers/numpad_controller").default);
application.register("overlay", require("../controllers/overlay_controller").default);
application.register("replace", require("../controllers/replace_controller").default);
application.register("toggle", require("../controllers/toggle_controller").default);
application.register("form-throttle", require("../controllers/form_throttle_controller").default);
application.register("focus", require("../controllers/focus_controller").default);
application.register("remove", require("../controllers/remove_controller").default);
application.register("selectable-list", require("../controllers/selectable-list").default);
application.register("match-datetime-fields", require("../controllers/match-datetime-fields").default);

// this would be an alternative, but that would load all controllers everywhere and we don’t want to ship codemirror on
// the store frontend for example
// import * as controllers from "../controllers/**/*_controller.js"
// const { default: controllersArray, filenames } = controllers

// controllersArray.map(({ default: c }, i) => {
//   const s = filenames[i].split("/")
//   application.register(identifierForContextKey(s[s.length - 1]), c)
// })

// Capacitor integration for custom POS iPad App
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

if (Capacitor.isNativePlatform()) {
  const change = (url) => {
    if (!url) return;

    console.log("changing to", url);
    const newUrl = url.replace("mdcpos:/", window.location.origin);
    window.location.href = newUrl;
  };

  App.getLaunchUrl().then(({ url } = {}) => {
    const last = localStorage.getItem("lastLaunchUrl");
    if (last === url) return;

    localStorage.setItem("lastLaunchUrl", url);
    console.log("getlaunchurl");
    change(url);
  });
  App.addListener("appUrlOpen", ({ url }) => {
    console.log("app url open listener");
    change(url);
  });
}
